// Color
// ––––––––––––––––––––––––––––––––––––––––––––––––––

$color-initial: #fff !default;
$color-primary: #daa923 !default;
$color-text: #333333 !default;
$color-secondary: #8f8e8f !default;
$color-tertiary: #f4f5f6 !default;
$color-quaternary: #f9f9f9 !default;
$color-quinary: #e1e1e1 !default;

$color-elin-blue: #175392 !default;
