// Footer
// ––––––––––––––––––––––––––––––––––––––––––––––––––

$footer-bg: #1e1e1e;

.footer {
  background-color: $footer-bg;
  padding: 0;
  padding-top: 50px;
  padding-bottom: 25px;

  &-title {
    font-family: "Source Sans Pro", sans-serif;
    color: $color-initial;
    line-height: 37px;
    letter-spacing: 1px;
    margin-top: -5px;
  }

  address {
    font-style: initial;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 1.8em;

    a {
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-list {
    list-style: none;
    line-height: 2.3em;

    li {
      width: 50%;
      float: left;
    }
    a,
    &-header {
      font-size: 14px;
      letter-spacing: 1.4px;
      font-family: "Poppins", sans-serif;
      color: inherit;
      text-transform: uppercase;
      margin-top: 25px;
    }
    &-header {
      color: $color-initial;
    }
    a {
      padding-bottom: 0;
      &:hover {
        padding-bottom: 1px;
        border-bottom: 2px solid;
      }
    }
  }
  &-social {
    &.column {
      max-width: 0px;
    }
  }
  &-meta {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 50px;
    padding-top: 50px;
    font-size: 14px;

    &-links {
      padding-top: 25px;
    }

    a {
      color: $color-initial;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 1.4px;
      font-family: "Source Sans Pro", sans-serif;
      margin-right: 50px;

      &:hover {
        color: $color-primary;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

@media (min-width: 40rem) {
  .footer {
    &-list {
      a,
      &-header {
        margin-top: 0;
      }
    }
    &-meta {
      &-links {
        padding-top: 0;
        text-align: right;
      }
    }
  }
}

@media (min-width: 960px) {
  .footer {
    border: 60px solid $color-initial;
    padding: 50px;
    padding-top: 100px;
    padding-bottom: 50px;

    &-social {
      &.column {
        max-width: 150px;
      }
    }
  }
}
