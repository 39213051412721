// Spacing
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.button,
button,
dd,
dt,
li {
  margin-bottom: 1rem;
}

fieldset,
input,
select,
textarea {
  margin-bottom: 31px;
}

blockquote,
dl,
figure,
form,
ol,
p,
pre,
table,
ul {
  margin-bottom: 40px;
}
