// Contact
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.contact {
  &-map {
    margin: 0;
    display: inline-block;
    width: 100%;
    min-height: 300px;
    min-height: 65vh;
    position: relative;

    @media (min-width: (1240px + 60px)) {
      border: 60px solid $color-initial;
      border-bottom: 0;
    }
  }
  &-details {
    &.column {
      max-width: 350px;
    }

    &-section {
      margin-bottom: 35px;
      color: #666666;
      background-repeat: no-repeat;
      padding-left: 62px;
      padding-top: 4px;

      a {
        color: inherit;

        &:hover {
          color: $color-primary;
        }
      }

      address {
        font-style: normal;
      }

      &-title {
        font-family: "Poppins", sans-serif;
        font-size: 21px;
        color: #333333;
        margin-bottom: 15px;
      }

      &.address {
        background-image: url(/img/map.svg);
      }
      &.phone {
        background-image: url(/img/phone.svg);
      }
      &.email {
        background-image: url(/img/email.svg);
      }
    }
  }
}
