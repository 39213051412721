// About
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.about {
  &-list {
    list-style-type: square;

    &-title {
      color: #333333;
      margin-top: 0.5em;
    }

    li {
      color: #333333;
      float: left;
      width: 100%;
      span {
        color: #666666;
      }
    }
  }
  &-sectors {
    padding-top: 120px;
    padding-bottom: 140px;

    &.without-padds {
      padding-top: 0;
      padding-bottom: 0;
    }

    .row {
      flex-wrap: wrap;
    }

    .column {
      width: 100%;
    }

    &-icon {
      border: 1px solid #dddedf;
      width: 96px;
      height: 96px;
      border-radius: 50%;
      margin: 0;
      display: inline-block;
      position: relative;
      transition: all 0.1s ease-in-out;
      margin-top: 20px;

      svg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        transition: all 0.1s ease-in-out;
        fill: #999999;
      }
      .fill-alt {
        fill: #fafbfc;
      }

      &:hover {
        background-color: $color-primary;
        border-color: $color-primary;

        svg {
          fill: $color-initial;
        }
        .fill-alt {
          fill: $color-primary;
        }
      }
    }
    &-title {
      display: block;
      margin-top: 32px;
      padding: 0 12px;
      color: #333333;
    }
  }

  &-plan {
    &-title {
      font-size: 38px;
      line-height: 46px;
    }
    &-subtitle {
      font-size: 19px;
      line-height: 29px;
      max-width: 808px;
      margin: auto;
      margin-bottom: 80px;
      display: block;
    }
    &-content {
      &.column {
        padding-left: 4vw;
      }
      &-title {
        font-size: 51px;
        color: #333333;
        margin-top: 10px;
        line-height: 61px;
      }
    }
  }
}

@media (min-width: 380px) {
  .about {
    &-sectors {
      .column {
        width: 50%;
      }
    }
  }
}

@media (min-width: 540px) {
  .about {
    &-list {
      li {
        width: 50%;
        margin-bottom: 2.5rem;
      }
    }
    &-sectors {
      .column {
        width: 33.3333333333%;
      }

      &-title {
        font-size: 21px;
      }
    }
  }
}

@media (min-width: 639px) and (max-width: 1000px) {
  .about {
    &-list {
      li {
        width: 100%;
      }
    }
  }
}

@media (min-width: 1100px) {
  .about {
    &-plan {
      &-content {
        &.column {
          padding-left: 110px;
        }
      }
    }
    &-sectors {
      .column {
        width: 16.6666666667%;
      }
    }
  }
}
