// Philanthropy
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.philanthropy {
  &-program {
    &-logo.column {
      max-width: 0;
    }

    table {
      color: #333333;
      strong {
        padding-right: 10px;
      }
    }
  }
  &-partner {
    &-cover {
      background-position: center;
      background-size: cover;
      background-image: url(/img/phil/partner.jpg);
      background-repeat: no-repeat;
      min-height: 300px;
    }
  }
}

@media (min-width: 30rem) {
  .philanthropy {
    &-program {
      &-logo.column {
        max-width: 88px;
        padding-right: 1rem;
      }
    }
  }
}

@media (min-width: 40rem) {
  .philanthropy {
    &-program {
      .container {
        max-width: 880px;
        padding: 0;
      }
      &-logo.column {
        max-width: 160px;
      }
    }
  }
}
