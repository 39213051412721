// Quote
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.quote {
  background-color: #00458b;
  margin: 0;
  padding: 110px 2rem;
  color: $color-initial;

  p {
    color: $color-initial;
    max-width: 800px;
    margin: 0 auto;
    font-size: 21px;
    line-height: 32px;
    background-repeat: no-repeat;
    background-image: url(/img/quote.svg);
    padding-top: 54px;

    // &:before {
    //   content: "“";
    //   display: block;
    //   font-size: 120px;
    //   opacity: 0.8;
    // }
  }
}
