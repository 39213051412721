// Cover
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.cover {
  margin: 0;
  display: inline-block;
  width: 100%;
  min-height: 150px;
  min-height: 35vh;
  position: relative;
  z-index: 0;

  // Image is only a placeholder, file gets loaded as a background image on cover-overlay.
  &-image {
    display: none;
  }
  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    background-position: center;
    background-size: cover;
    z-index: 1;
  }
  &-content {
    position: relative;
    z-index: 2;
    padding-top: 80px;
    padding-bottom: 120px;
    font-family: "Source Sans Pro", sans-serif;
    text-shadow: 0 2px 27px rgba(0, 0, 0, 0.5);

    &-title {
      font-weight: 700;
      font-size: 36px;
      max-width: 620px;
      color: $color-initial;
    }
    &-paragraph {
      font-weight: 700;
      font-size: 19px;
      line-height: 29px;
      max-width: 495px;
      color: $color-initial;
    }
    .button {
      box-shadow: none;
      font-family: "Source Sans Pro", sans-serif;
    }
  }
  &-popout {
    position: relative;
    z-index: 2;
    padding-top: 90px;
    padding-bottom: 120px;
    background-color: $color-primary;
    text-align: center;

    &-title {
      // max-width: 620px;
      color: $color-initial;
    }
    &-paragraph {
      font-size: 19px;
      line-height: 29px;
      margin: 0 auto;
      max-width: 800px;
      color: $color-initial;
    }

    &.bg-blue {
      background-color: #00458b;
    }
  }

  // Bordered cover section
  &-bordered {
    @media (min-width: (1240px + 60px)) {
      border: 60px solid $color-initial;
      border-bottom: 0;
    }
  }
}

@media (min-width: 420px) {
  .cover {
    &-content {
      &-title {
        font-size: 48px;
      }
    }
  }
}

@media (min-width: 700px) {
  .cover {
    min-height: 300px;
    min-height: 65vh;
    &-content {
      padding-top: 160px;
      padding-bottom: 240px;

      &-title {
        font-size: 72px;
      }
    }
    &-popout {
      margin-top: 120px;
      margin-bottom: 120px;
    }
  }
}
