// Utility
// ––––––––––––––––––––––––––––––––––––––––––––––––––

// Clear a float with .clearfix
.clearfix {
  &:after {
    clear: both;
    content: " ";
    display: table;
  }
}

// Float either direction
.float-left {
  float: left;
}

.float-right {
  float: right;
}

// Text align
.text {
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
}

// Spacing
.padds {
  &-top {
    &-medium {
      padding-top: 25px;
    }
    &-large {
      padding-top: 50px;
    }
  }
  &-bottom {
    &-large {
      padding-bottom: 75px;
    }
  }
}

// Backgrounds
.bg {
  &-primary {
    background-color: $color-primary;
    * {
      color: $color-initial;
    }
  }
  &-grey {
    background-color: #fafbfc;
  }
  &-blue {
    background-color: #00458b;
  }
}

// Text
.text {
  // Color
  &-primary {
    color: $color-primary;
  }
  &-darker {
    color: #333333;
  }
  // Size
  &-larger {
    font-size: 19px;
  }
}

@media (min-width: 40rem) {
  .padds {
    &-top {
      &-medium {
        padding-top: 50px;
      }
      &-large {
        padding-top: 100px;
      }
    }
    &-bottom {
      &-large {
        padding-bottom: 150px;
      }
      &-medium {
        padding-bottom: 60px;
      }
    }
  }
}

// Mobile only
@media (max-width: 40rem) {
  .padds {
    &-top {
      &-mobile {
        padding-top: 50px;
      }
    }
  }
}
