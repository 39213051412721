// Link
// ––––––––––––––––––––––––––––––––––––––––––––––––––

$click-out-size: 32px;

a {
  color: $color-primary;
  text-decoration: none;
  transition: all 0.1s ease-in-out;

  &:focus,
  &:hover {
    color: #333333;
  }
}

.click-out {
  position: relative;
  padding-right: $click-out-size;
  text-transform: uppercase;

  &:after {
    content: "";
    height: 100%;
    display: inline-block;
    width: 32px;
    /* background-color: red; */
    background-image: url(/img/arrow.svg);
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.1s ease-in-out;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 17px;
  }

  &:hover {
    &:after {
      filter: grayscale(100%);

      transform: translateX(5px);
    }
  }
}
