// Connect
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.connect {
  background-color: #fafbfc;

  &-section {
    &-title {
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 3.2px;
      text-transform: uppercase;
      color: #333333;
    }
  }
  &-p {
    max-width: 390px;
  }
  &-social {
    display: inline-block;
    width: 58px;
    height: 58px;
    text-align: center;
    padding: 19px;
    margin-right: 20px;
    border-radius: 50%;
    border: 1px solid;

    svg {
      width: 16px;
      height: 16px;
      fill: $color-initial;
    }

    &.twitter {
      background-color: #00a0d1;
      border-color: darken(#00a0d1, 10%);
    }
    &.fb {
      background-color: #3b5998;
      border-color: darken(#3b5998, 10%);
    }
    &.linkedin {
      background-color: #0e76a8;
      border-color: darken(#0e76a8, 10%);
    }
  }
  &-sep {
    &.column {
      max-width: 40px;
    }
    position: relative;

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      height: 80%;
      top: 10%;
      width: 2px;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-color: rgba(0, 0, 0, 0.1);
      opacity: 0;
    }

    &-text {
      font-size: 14px;
      text-transform: uppercase;
      padding: 20px 0;
      color: #333333;
      background-color: #fafbfc;
      position: relative;
      top: 45%;
      opacity: 0;
    }
  }
}

@media (min-width: 40rem) {
  .connect {
    &-sep {
      &:before {
        opacity: 1;
      }

      &-text {
        opacity: 1;
      }
    }
  }
}

@media (min-width: 920px) {
  .connect {
    &-sep {
      &.column {
        max-width: 310px;
      }
    }
  }
}
