// Navigation
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.nav {
  background-color: $color-quaternary;
  z-index: 1;
  position: relative;

  // Remove padding from container since it's added to nav items
  .container {
    padding-left: 0;
    padding-right: 0;
  }

  &-list {
    margin: 0;
    list-style: none;
    text-align: center;
    text-transform: uppercase;
    color: $color-text;

    li {
      display: inline-block;
      margin: 0;
      position: relative;

      // Add border to item
      &:after {
        content: "";
        transition: all 0.18s ease-in-out;
        width: 12px;
        height: 3px;
        position: absolute;
        bottom: -1px;
        left: 2rem;
        background-color: rgba($color-quaternary, 0);
      }

      // Interaction
      &:hover {
        &:after {
          width: 36px;
          background-color: darken($color-quaternary, 10%);
        }
      }
      &.active {
        &:after {
          width: 36px;
          background-color: $color-primary;
        }
      }

      &:hover {
        .nav-list-sub {
          transform: scaleY(1);
          opacity: 1;
        }
      }
    }
    a {
      display: inline-block;
      transition: color 0.15s ease-in-out;
      color: $color-text;
      padding: 19px 20px;
      font-size: 14px;
      font-weight: 700;
      font-family: "Poppins", sans-serif;
      letter-spacing: 1.4px;

      &:hover {
        color: $color-primary;
      }
    }

    // Sub nav
    &-sub {
      position: absolute;
      margin: 0;
      left: 20px;
      top: 100%;
      width: 290px;
      transition: all 0.25s ease-in-out;
      transition-delay: 0.15s;
      transform: scaleY(0);
      transform-origin: top left;
      opacity: 0;

      li {
        width: 100%;
        &:after {
          display: none;
        }

        $color-2: #7b7b7b;
        $color-1: #2f2f2f;
        $steps: 3;

        @for $i from 0 to $steps {
          &:nth-of-type(#{$i + 1}) {
            background: mix($color-1, $color-2, percentage($i / ($steps - 1)));
          }
        }

        a {
          width: 100%;
          padding: 12px 18px;
          color: $color-initial;
          text-align: left;
          font-size: 11px;
        }
      }
    }
  }
  &-close {
    display: none;
  }
}

@media (max-width: 960px) {
  .nav {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    max-width: 300px;
    z-index: 3;
    transition: all 0.25s ease-in-out;
    transform: translateX(-100%);
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 10px 0px 0px rgba(0, 0, 0, 0);
    padding-top: 40px;

    &-list {
      li {
        width: 100%;
        border-bottom: 1px solid lighten($color-quaternary, 2%);

        a {
          width: 100%;
          padding: 8px;
          text-align: left;
        }

        &:after {
          display: none;
        }
      }
      &-sub {
        transform: scaleY(1);
        opacity: 1;
        position: relative;
        left: 0;
        width: 100%;

        li {
          background-color: lighten($color-quaternary, 2%) !important;
          border-bottom: 1px solid lighten($color-quaternary, 1%);

          &:last-of-type {
            border-bottom: 0;
          }

          a {
            color: $color-text;
            padding: 12px 8px;
          }
        }
      }
    }
    &-close {
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;

      img {
        width: 24px;
      }
    }

    &.show {
      transform: translateX(0%);
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1),
        10px 0px 40px rgba(0, 0, 0, 0.05);
    }
  }
}
