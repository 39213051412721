// Button
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.button,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  background-color: $color-primary;
  border: 0.1rem solid $color-primary;
  border-radius: 0.4rem;
  color: $color-initial;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  height: 5.8rem;
  letter-spacing: 2px;
  line-height: 5.8rem;
  padding: 0 50px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  min-width: 240px;

  &:focus,
  &:hover {
    background-color: $color-secondary;
    border-color: $color-secondary;
    color: $color-initial;
    outline: 0;
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;

    &:focus,
    &:hover {
      background-color: $color-primary;
      border-color: $color-primary;
    }
  }

  &.button-outline {
    background-color: transparent;
    color: $color-primary;

    &:focus,
    &:hover {
      background-color: transparent;
      border-color: $color-secondary;
      color: $color-secondary;
    }

    &[disabled] {
      &:focus,
      &:hover {
        border-color: inherit;
        color: $color-primary;
      }
    }

    &-subtle {
      border-color: #c5c5c5;
      color: #333;
    }
  }

  &.button-clear {
    background-color: transparent;
    border-color: transparent;
    color: $color-primary;

    &:focus,
    &:hover {
      background-color: transparent;
      border-color: transparent;
      color: $color-secondary;
    }

    &[disabled] {
      &:focus,
      &:hover {
        color: $color-primary;
      }
    }
  }
}

@media (min-width: 40rem) {
  .button {
    &.button-spacer {
      margin-right: 30px;
    }
  }
}
