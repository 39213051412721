// Header
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.header {
  padding-top: 2rem;
  padding-bottom: 2rem;

  // Header buttons
  &-social,
  &-search-toggle,
  &-menu-toggle,
  &-social-mobile {
    display: inline-block;
    margin-right: 2rem;

    svg {
      width: 20px;
      height: 20px;
    }
    // Fill SVG path
    path {
      transition: fill 0.15s ease-in-out;
      fill: $color-secondary;
    }

    // Hover interaction
    &:hover {
      path {
        fill: $color-primary;
      }
    }

    // Remove margin on last item
    &:last-of-type {
      margin-right: 0;
    }
  }
  &-social {
    display: none;

    &-mobile {
      display: inline-block;
      padding: 2rem 10px;
      padding-right: 0;
      margin-right: 10px;
    }
  }
  &-search-toggle,
  &-menu-toggle {
    cursor: pointer;
  }

  // Logo section
  &-logo {
    &.column {
      max-width: 240px;
    }
    &-text {
      font-size: 10px;
      font-size: 3.1vw;
      display: inline-block;
      vertical-align: middle;
      color: $color-elin-blue;
      text-align: center;
      font-family: comfortaa, Arial, Helvetica, sans-serif;
      margin-bottom: 0;
      padding-bottom: 0;
      font-weight: 500;
      letter-spacing: 0.1px;
    }
  }
}

@media (min-width: 40rem) {
  .header {
    &-logo {
      &-text {
        font-size: 20px;
      }
    }
  }
}

@media (min-width: 960px) {
  .header {
    &-social {
      display: inline-block;
      &-mobile {
        display: none;
      }
    }
    &-menu-toggle {
      display: none;
    }
  }
}
