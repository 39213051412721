// Base
// ––––––––––––––––––––––––––––––––––––––––––––––––––

// Set box-sizing globally to handle padding and border widths
*,
*:after,
*:before {
  box-sizing: inherit;
}

// The base font-size is set at 62.5% for having the convenience
// of sizing rems in a way that is similar to using px: 1.6rem = 16px
html {
  box-sizing: border-box;
  font-size: 62.5%;
}

// Default body styles
body {
  color: $color-secondary;
  font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 1.6;
}
