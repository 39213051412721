// Services
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.services {
  &-plan {
    &-title {
      font-size: 38px;
      line-height: 46px;
    }
    &-subtitle {
      max-width: 880px;
      margin-left: auto;
      margin-right: auto;
    }
    &-quote {
      max-width: 880px;
      margin-left: auto;
      margin-right: auto;
      font-size: 30px;
      color: #333333;
      border-left: 13px solid #00458b;
      padding-left: 40px;
    }
  }
  &-title {
    font-size: 51px;
    line-height: 61px;
  }
  &-content {
    color: #666666;
  }
  &-partner {
    &-content {
      max-width: 540px;
      margin: 0 auto;
      padding-top: 160px;
      padding-bottom: 140px;
      padding-left: 2rem;
      padding-right: 2rem;
    }
    &-cover {
      background-position: center;
      background-size: cover;
      background-image: url(/img/services/partner.jpg);
      background-repeat: no-repeat;
      min-height: 300px;
    }
  }
}
