// Home
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.home {
  &-mission {
    &-text {
      max-width: 490px;
    }
  }
  &-gallery {
    // min-height: 490px;

    .row {
      height: 50%;
      min-height: 245px;
    }
    .column {
      padding: 0;
      position: relative;
      border-left: 1rem solid $color-initial;
      border-bottom: 1rem solid $color-initial;
      overflow: hidden;

      &:first-child {
        border-left-width: 0;
      }
    }
    .item {
      transition: all 0.15s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      &.icon {
        background-color: #f9fafc;
        background-size: auto;

        &:hover {
          background-color: darken(#f9fafc, 4%);
        }
      }
    }

    &-large {
      // min-height: 620px;

      .row {
        min-height: 610px;
      }
      .column {
        border-left-width: 0;
      }
    }
    // Single row
    &-single-row {
      .row {
        height: 100%;
        min-height: 610px;
      }
      .column {
        border-left-width: 0;
      }
    }
  }
  &-highlights {
    padding-top: 120px;

    .column {
      position: relative;
      padding-bottom: 120px;
    }
    &-title {
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 2.1px;
      font-size: 21px;
      font-weight: 300;
      margin-top: 35px;
      margin-bottom: 65px;
      position: relative;
      z-index: 2;
    }
    &-p {
      margin-bottom: 0;
      font-weight: 300;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
    &-number {
      font-size: 120px;
      position: absolute;
      top: -15px;
      left: 0;
      right: 0;
      width: 150px;
      height: 120px;
      line-height: 1;
      text-align: center;
      margin: 0 auto;
      color: rgba(0, 0, 0, 0.065);
      z-index: 1;
    }
  }
}

@media (min-width: 40rem) {
  .home {
    &-gallery {
      .row {
        height: 50%;
        min-height: 245px;
      }

      &-large {
        // min-height: 620px;

        .row {
          min-height: 310px;
        }
        .column {
          border-left-width: 1rem;
        }
      }
      // Single row
      &-single-row {
        .row {
          min-height: 310px;
        }
        .column {
          border-left-width: 1rem;
        }
      }
    }
  }
}
