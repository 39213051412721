// Heading
// Used for page title sections
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.heading {
  padding-top: 40px;
  padding-bottom: 23px;
  &-page {
    display: block;
    color: #999999;
    padding-bottom: 30px;
  }
  &-title {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-size: 36px;
    color: #000000;
    font-weight: 900;
  }
}

@media (min-width: 420px) {
  .heading {
    padding-top: 40px;
    padding-bottom: 23px;
    &-title {
      font-size: 48px;
    }
  }
}

@media (min-width: 700px) {
  .heading {
    padding-top: 40px;
    padding-bottom: 23px;
    &-title {
      font-size: 72px;
    }
  }
}
