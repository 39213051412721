// Typography
// ––––––––––––––––––––––––––––––––––––––––––––––––––

b,
strong {
  font-weight: bold;
}

p {
  margin-top: 0;
  color: #666666;
  letter-spacing: 0.4px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  letter-spacing: -0.1rem;
  margin-bottom: 2rem;
  margin-top: 0;
  color: #262626;
}

h1 {
  font-size: 4.6rem;
  line-height: 1.2;
}

h2 {
  font-size: 38px;
  line-height: 61px;
}

h3 {
  font-size: 2.8rem;
  line-height: 1.3;
}

h4 {
  font-size: 21px;
  letter-spacing: 0.5px;
  line-height: 32px;
}

h5 {
  font-size: 1.8rem;
  letter-spacing: -0.05rem;
  line-height: 1.5;
}

h6 {
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 1.4;
}
