// Form
// ––––––––––––––––––––––––––––––––––––––––––––––––––

input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
textarea,
select {
  appearance: none;
  background-color: transparent;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
  box-shadow: none;
  box-sizing: inherit;
  height: 4.8rem;
  padding: 0.6rem 1rem;
  width: 100%;

  &:focus {
    border-color: $color-primary;
    outline: 0;
  }
}

select {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="#d1d1d1" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>')
    center right no-repeat;
  padding-right: 3rem;

  &:focus {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="#9b4dca" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>');
  }
}

textarea {
  min-height: 177px;
}

label,
legend {
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0.5rem;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.1px;
  color: #333333;
}

fieldset {
  border-width: 0;
  padding: 0;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
}

.label-inline {
  display: inline-block;
  font-weight: normal;
  margin-left: 0.5rem;
}
